@import './shared/styles/_vuetify-overrides.scss';



















































































































































































a {
  text-decoration: none;
}
.cassie-disabled-button {
  color: var(--global-cassie-disabled-button-color);
  background-color: var(--global-cassie-disabled-button-background-color);
  border: var(--global-cassie-disabled-button-border);
  -webkit-box-shadow: revert;
  box-shadow: revert;
}
